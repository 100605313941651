














































































































































































































































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Debounce } from "vue-debounce-decorator";
interface dataItem {
  id: number | any;
  nombre: string | any;
}
interface dataFilter {
  categorias: Array<dataItem>;
  fechas: number | any;
  texto: string | any;
}
interface dataOfertas {
  codigo: string | any;
  cantidad: string | any;
  descripcion: string | any;
  estado: number | any;
  fecha_publicacion: string | any;
  fecha_vigencia: string | any;
  id_categoria: number | any;
  id: number | any;
  nombre: string | any;
  direccion: string | any;
  empresa: string | any;
}
@Component
export default class OfertasOmil extends Vue {
  private win = window;
  private drawer = false;
  private postular = false;
  private tab = "";
  private expanded = false;
  private expandedFecha = false;
  private dataDialog = false;
  private textoBuscar = "";
  private categoriasOmil: Array<any> = [];
  private ofertas: Array<any> = [];
  private dataBuscar: dataFilter = {
    categorias: [],
    fechas: 0,
    texto: ""
  };
  private ofertasData: dataOfertas = {
    codigo: "",
    cantidad: "",
    descripcion: "",
    estado: null,
    fecha_publicacion: "",
    fecha_vigencia: "",
    id_categoria: null,
    id: null,
    nombre: "",
    direccion: null,
    empresa: null
  };

  private fechas = [
    {
      id: 0,
      label: "Todos",
      seleccionado: false
    },
    {
      id: 1,
      label: "Día anterior",
      seleccionado: false
    },
    {
      id: 2,
      label: "Los últimos 3 días",
      seleccionado: false
    },
    {
      id: 3,
      label: "Última semana",
      seleccionado: false
    },
    {
      id: 4,
      label: "Último mes",
      seleccionado: false
    }
  ];

  private lastFiltro = "";
  private currentPage = 1;
  private total = 0;
  @Watch("textoBuscar")
  private selectTextoBuscar(newValue: any) {
    this.dataBuscar.texto = newValue;
  }

  private mounted() {
    this.getCategorias();
    this.buscar();
  }

  private getCategorias() {
    this.$axios
      .get("categorias")
      .then(res => {
        const responseData = res.data;
        responseData.forEach((categoria: any) => {
          if (categoria.id > 0) {
            this.categoriasOmil.push({
              id: categoria.id,
              nombre: categoria.nombre,
              seleccionado: false
            });
          }
        });
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  private vaciarInput() {
    this.textoBuscar = "";
    this.buscar();
  }
  private categoria() {
    if (this.tab == "") {
      this.expanded = true;
    }
    if (this.tab == "fecha") {
      this.expanded = true;
    }

    if (this.tab == "categorias") {
      this.expanded = false;
      this.tab = "";
    }
  }
  private fecha() {
    if (this.tab == "") {
      this.expandedFecha = true;
    }
    if (this.tab == "categorias") {
      this.expandedFecha = true;
    }

    if (this.tab == "fecha") {
      this.expandedFecha = false;
      this.tab = "";
    }
  }

  private mostrarDatosDesktop(id_ofertas: any) {
    for (let i = 0; i < this.ofertas.length; i++) {
      if (this.ofertas[i].id == id_ofertas) {
        this.ofertasData.codigo = this.ofertas[i].codigo;
        this.ofertasData.cantidad = this.ofertas[i].cantidad;
        this.ofertasData.descripcion = this.ofertas[i].descripcion;
        this.ofertasData.estado = this.ofertas[i].estado;
        this.ofertasData.fecha_publicacion = this.ofertas[i].fecha_publicacion;
        this.ofertasData.fecha_vigencia = this.ofertas[i].fecha_vigencia;
        this.ofertasData.id_categoria = this.ofertas[i].id_categoria;
        this.ofertasData.id = this.ofertas[i].id;
        this.ofertasData.nombre = this.ofertas[i].nombre;
        this.ofertasData.direccion = this.ofertas[i].direccion;
        this.ofertasData.empresa = this.ofertas[i].empresa;
      }
    }
  }

  private mostrarDatosMobile(id_ofertas: any) {
    for (let i = 0; i < this.ofertas.length; i++) {
      if (this.ofertas[i].id == id_ofertas) {
        this.ofertasData.codigo = this.ofertas[i].codigo;
        this.ofertasData.cantidad = this.ofertas[i].cantidad;
        this.ofertasData.descripcion = this.ofertas[i].descripcion;
        this.ofertasData.estado = this.ofertas[i].estado;
        this.ofertasData.fecha_publicacion = this.ofertas[i].fecha_publicacion;
        this.ofertasData.fecha_vigencia = this.ofertas[i].fecha_vigencia;
        this.ofertasData.id_categoria = this.ofertas[i].id_categoria;
        this.ofertasData.id = this.ofertas[i].id;
        this.ofertasData.nombre = this.ofertas[i].nombre;
        this.ofertasData.direccion = this.ofertas[i].direccion;
        this.ofertasData.empresa = this.ofertas[i].empresa;
        this.dataDialog = true;
      }
    }
  }

  private seleccionCategoria(id_categoria: any) {
    for (let i = 0; i < this.categoriasOmil.length; i++) {
      if (this.categoriasOmil[i].id == id_categoria) {
        this.categoriasOmil[i].seleccionado = true;
        this.expanded = false;
        this.tab = "";
        this.dataBuscar.categorias.push({
          id: this.categoriasOmil[i].id,
          nombre: this.categoriasOmil[i].nombre
        });
      }
    }
    this.buscar();
  }
  private deseleccionarCategoria(id_categoria: any) {
    for (let i = 0; i < this.categoriasOmil.length; i++) {
      if (this.categoriasOmil[i].id == id_categoria) {
        this.categoriasOmil[i].seleccionado = false;
      }
      for (let j = 0; j < this.dataBuscar.categorias.length; j++) {
        if (this.dataBuscar.categorias[j].id == id_categoria) {
          this.dataBuscar.categorias.splice(j, 1);
          this.expanded = false;
          this.tab = "";
        }
      }
    }
    this.buscar();
  }

  private seleccionFecha(id_fecha: any) {
    for (let i = 0; i < this.fechas.length; i++) {
      if (this.fechas[i].id == id_fecha) {
        this.fechas[i].seleccionado = true;
        this.dataBuscar.fechas = id_fecha;
        this.expandedFecha = false;
        this.tab = "";
      } else {
        this.fechas[i].seleccionado = false;
      }
    }
    this.buscar();
  }
  private deseleccionarFecha(id_fecha: any) {
    for (let i = 0; i < this.fechas.length; i++) {
      if (this.fechas[i].id == id_fecha) {
        this.fechas[i].seleccionado = false;
        this.dataBuscar.fechas = 0;
        this.expanded = false;
        this.tab = "";
      }
    }

    this.buscar();
  }

  @Debounce(500)
  private buscar() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    var path = "";
    for (let i = 0; i < this.dataBuscar.categorias.length; i++) {
      if (i == 0) {
        path = path + "?categorias[]=" + this.dataBuscar.categorias[i].id;
      } else {
        path = path + "&categorias[]=" + this.dataBuscar.categorias[i].id;
      }
    }

    if (this.dataBuscar.fechas != null) {
      if (path == "") {
        path = path + "?";
      } else {
        path = path + "&";
      }

      path = path + "fechas=" + this.dataBuscar.fechas;
    }

    if (this.dataBuscar.texto != "") {
      if (path == "") {
        path = path + "?";
      } else {
        path = path + "&";
      }

      path = path + "texto=" + this.dataBuscar.texto;
    }

    this.$axios
      .get("buscar" + path)
      .then((res: any) => {
        this.ofertas = res.data.ofertas;
        this.mostrarDatosDesktop(this.ofertas[0].id);
        this.textoBuscar = "";
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
      });
  }

  private formatFecha(fecha: any) {
    let date = fecha.split(" ", 2);
    let newDate = date[0].split("-");
    return newDate[2] + "-" + newDate[1] + "-" + newDate[0];
  }
}
